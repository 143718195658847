export default class MainNav {
    constructor() {
        this.menuCall = document.querySelector("#menu-call-container .menu-call");
        this.menuLinks = document.querySelectorAll("#menuEntries a");
    }

    run() {
        let body = document.querySelector("body");

        this.menuCall.addEventListener("click", (event) => {
            console.log("Hey!");
            event.preventDefault();
            body.classList.toggle("menu");
        });

        // this.menuLinks.forEach((elem) => {
        //     elem.addEventListener("click", (event) => {
        //         body.classList.remove("menu");
        //     });
        // });
    }
}
