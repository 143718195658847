/**
 * Document ready listener and runner
 * @param (function) fn - Function to run when document is ready.
 */
export const docReady = (fn) => {
    if (document.readyState != "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
};

/**
 * Window ready listener and runner
 * @param (function) fn - Function to run when window is ready.
 */
export const windowReady = (fn) => {
    if (document.readyState === "complete") {
        fn();
    } else {
        window.addEventListener("load", fn);
    }
};

/** Async await function. */
export const sleep = (m) => new Promise((r) => setTimeout(r, m));

/**
 * Generates a new UUID
 */
export const Uuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
};

export const getObjectPath = (object, path) => {
    if (!Array.isArray(path)) {
        path = [path];
    }

    let index = 0;
    let length = path.length;

    while (object != null && index < length) {
        object = object[path[index++]];
    }

    return index && index == length ? object : undefined;
};

/**
 * Empties a DOM node by removing all of its children.
 * @param (Node) elem - The DOM node to be emptied.
 */
export const emptyNode = (elem) => {
    while (elem.firstChild) {
        elem.removeChild(elem.firstChild);
    }
};

/**
 * Replaces dangerous HTML characters for the respective HTML entity names.
 * @param (string) str - The original string to be escaped.
 * @return (string) The escaped string.
 */
export const escapeHtml = (str) => {
    const escapeMap = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#x27;",
        "`": "&#x60;",
    };

    let strReg = "";
    Object.keys(escapeMap).forEach((key, idx) => {
        if (idx > 0) {
            strReg += "|";
        }

        strReg += key;

        if (key === "&") {
            strReg += "(?!";
            Object.keys(escapeMap).forEach((subKey, subIdx) => {
                if (subIdx > 0) {
                    strReg += "|";
                }

                strReg += escapeMap[subKey].substring(1);
            });
            strReg += ")";
        }
    });

    return str.replace(RegExp(strReg, "g"), (s) => {
        return escapeMap[s];
    });
};

/**
 * The inverse of escapeHtml.
 * @param (string) str - The original string to be escaped.
 * @return (string) The escaped string.
 */
export const unescapeHtml = (str) => {
    const escapeMap = {
        "&amp;": "&",
        "&lt;": "<",
        "&gt;": ">",
        "&quot;": '"',
        "&#x27;": "'",
        "&#x60;": "`",
    };

    let strReg = "";
    Object.keys(escapeMap).forEach((key, idx) => {
        if (idx > 0) {
            strReg += "|";
        }
        strReg += key;
    });

    return str.replace(RegExp(strReg, "g"), (s) => {
        return escapeMap[s];
    });
};

/**
 * Converts template string into a HTML template.
 * @param (array) strings - Array containing the original string parts.
 * @param (array) values - Array containing the original values to concatenate.
 * @return (template) An HTML Template containing DOM elements.
 */
export const html = (strings, ...values) => {
    values.forEach((val, idx) => {
        if (typeof val === "object" && "tagName" in val && val.tagName === "TEMPLATE") {
            values[idx] = val.innerHTML;
        } else {
            values[idx] = escapeHtml(val);
        }
    });

    let htmlStr = strings.reduce((prev, next, idx) => `${prev}${next}${values[idx] || ""}`, "");

    let templ = document.createElement("template");
    templ.innerHTML = htmlStr;
    return templ;
};

export const capitalizeFirst = (string) => {
    return string[0] ? `${string[0].toUpperCase()}${string.substring(1)}` : "";
};

export const htmlReplace = (targetElem, template) => {
    emptyNode(targetElem);
    targetElem.append(template.content);
};

export const htmlPrepend = (targetElem, template) => {
    targetElem.prepend(template.content);
};

export const htmlAppend = (targetElem, template) => {
    targetElem.append(template.content);
};
