import { html, htmlReplace, emptyNode } from "./utils/utils.js";

export default class PopBox {
    constructor() {
        this.links = document.querySelectorAll(".toPopBox");
    }

    run() {
        let self = this;
        this.links.forEach((elem) => {
            elem.addEventListener("click", (event) => {
                event.preventDefault();
                let popId = elem.dataset.popBoxId;
                let popType = elem.dataset.popBoxType;
                let popLang = elem.dataset.popLang;

                if (popId) {
                    self.pop(popId, popType, popLang);
                }
            });
        });

        let body = document.querySelector("body");
        body.addEventListener("click", (event) => {
            let targetElem = event.target;
            if (targetElem.id === "popBoxTintLayer" || targetElem.classList.contains("popClose")) {
                self.cleanUp();
            }
        });
    }

    async pop(popId, popType, popLang) {
        let htmlStr = false;

        let popDir = "/pop_boxes/";

        if (popType === "collection") {
            popDir = "/pop_boxes/docs/";
        }

        if (popType === "collection_europe_with_us") {
            popDir = "/pop_boxes/docs_europe_with_us/";
        }

        if (popType === "crono") {
            popDir = "/pop_boxes/crono/";
        }

        switch (popLang) {
            case "en":
                popDir += "en/";
                break;
            case "fr":
                popDir += "fr/";
                break;
            default:
                popDir += "pt/";
        }

        let fetchResponse = await fetch(popDir + popId + ".html");

        if (fetchResponse.headers.get("content-type").includes("text/html")) {
            htmlStr = await fetchResponse.text();
        }

        if (htmlStr) {
            let body = document.querySelector("body");
            let isEuropeWithUs = body.classList.contains("europe_with_us_exhibit")

            // popBoxTintLayer
            let popBoxTintLayer = html`<div id="popBoxTintLayer"></div>`;

            // popBoxContainer
            let contentHTMLString =
                "<div id='popBoxContainerContainer'><div id='popBoxContainer'>" +
                // "<div class='popClose'><img src='/assets/media/graphics/cross.png'></div>" +
                "<div id='popBoxContainerInner'>" +
                htmlStr +
                "</div></div></div>";
            let popBoxContainer = html([contentHTMLString]);

            let popInfo = popBoxContainer.content.querySelector(".pop_info");

            let closeElem = html`<div class="popClose">
                <img src="/assets/media/graphics/cross.png" />
            </div>`;

            if (isEuropeWithUs) {
                closeElem = html`<div class="popClose">
                    <img src="/assets/media/graphics/cross_dark.png" />
                </div>`;
            }

            popInfo.appendChild(closeElem.content.firstChild);

            body.appendChild(popBoxTintLayer.content.firstChild);
            body.appendChild(popBoxContainer.content.firstChild);
        }
    }

    cleanUp() {
        let popBoxTintLayer = document.querySelectorAll("#popBoxTintLayer");
        let popBoxContainer = document.querySelectorAll("#popBoxContainer");

        popBoxTintLayer.forEach((elem) => {
            emptyNode(elem);
            elem.remove();
        });

        popBoxContainer.forEach((elem) => {
            emptyNode(elem);
            elem.remove();
        });
    }
}
