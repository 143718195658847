import { html } from "./utils/utils.js";

export default class VerticalNav {
    constructor() {
        this.homeElem = document.querySelector("[data-home-section='true']");
        this.navElems = document.querySelectorAll("[data-vertical-nav='true']");
        this.excludeNavElems = document.querySelectorAll("[data-vertical-nav='false']");
        this.ticking = false;

        if (this.navElems.length > 0) {
            this.createBarElem();
        }
    }

    createBarElem() {
        let barElem = html`<div id="verticalNav" class="hidden"></div>`;
        let barRootElem = barElem.content.firstElementChild;

        if (this.homeElem) {
            let homeElem = html`<a href="#${this.homeElem.id}"><div class="vNavHome"></div></a>`;
            barRootElem.appendChild(homeElem.content.firstChild);
        }

        this.navElems.forEach((elem) => {
            let barElem = html`<a
                name="${elem.id}"
                href="#${elem.id}"
                data-selector-name="${elem.dataset.sectionname}"
                ><span class="vNavText">${elem.dataset.sectionname}</span>
                <div class="vNavCircle"></div
            ></a>`;
            barRootElem.appendChild(barElem.content.firstChild);
        });

        document.querySelector("body").appendChild(barRootElem);
    }

    run() {
        if (this.navElems.length > 0) {
            let self = this;
            window.addEventListener(
                "scroll",
                function (e) {
                    if (!self.ticking) {
                        window.requestAnimationFrame(function () {
                            self.update();
                            self.ticking = false;
                        });

                        self.ticking = true;
                    }
                },
                { passive: true },
            );
        }
    }

    isInViewport(element) {
        let x = element.getBoundingClientRect().left;
        let y = element.getBoundingClientRect().top;
        let ww = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        let hw = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        let w = element.clientWidth;
        let h = element.clientHeight;
        return y < hw && y + h > 0 && x < ww && x + w > 0;
    }

    update() {
        let viewableSections = [];
        let show = true;

        let self = this;
        this.navElems.forEach((elem) => {
            if (self.isInViewport(elem)) {
                viewableSections.push(elem.dataset.sectionname);
            }
        });

        let verticalNavElem = document.querySelector("#verticalNav");

        // reset selection
        let selectedElem = document.querySelectorAll("#verticalNav a");
        selectedElem.forEach((elem) => {
            elem.classList.remove("selected");
            if (viewableSections.includes(elem.dataset.selectorName)) {
                elem.classList.add("selected");
            }
        });

        // check if it should be visible
        this.excludeNavElems.forEach((elem) => {
            if (self.isInViewport(elem)) {
                show = false;
            }
        });

        if (!show) {
            verticalNavElem.classList.add("hidden");
        } else {
            verticalNavElem.classList.remove("hidden");
        }
    }
}
