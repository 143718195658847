import { html } from "./utils/utils.js";

export default class Crono {
    constructor() {
        this.cronoCall = document.querySelectorAll(".crono_side");
        this.axis = document.querySelectorAll("[data-crono='true']");
        this.excludeNavElems = document.querySelectorAll("[data-vertical-nav='false']");
        this.ticking = false;
        this.showingCrono = false;
        this.currentSection = null;
    }

    run() {
        if (this.cronoCall) {
            this.cronoCall.forEach((elem) => {
                elem.addEventListener("click", this.switchCrono.bind(this));

                let self = this;
                window.addEventListener(
                    "scroll",
                    function (e) {
                        if (!self.ticking) {
                            window.requestAnimationFrame(function () {
                                self.update();
                                self.ticking = false;
                            });

                            self.ticking = true;
                        }
                    },
                    { passive: true },
                );
            });

            this.update();
        }
    }

    switchCrono() {
        if (this.showingCrono) {
            this.showingCrono = false;
            let cronoContainer = document.querySelector("#crono-axis" + this.currentSection);
            document.querySelector("body").classList.remove("show-crono");
            cronoContainer.classList.remove("show");
        } else {
            this.showingCrono = true;
            let cronoContainer = document.querySelector("#crono-axis" + this.currentSection);
            document.querySelector("body").classList.add("show-crono");
            cronoContainer.classList.add("show");
        }
    }

    isInViewport(element) {
        let x = element.getBoundingClientRect().left;
        let y = element.getBoundingClientRect().top;
        let ww = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        let hw = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        let w = element.clientWidth;
        let h = element.clientHeight;
        return y < hw && y + h > 0 && x < ww && x + w > 0;
    }

    updateCaller(axisNumber) {
        console.log("update");

        let show = true;
        let self = this;
        this.excludeNavElems.forEach((elem) => {
            if (self.isInViewport(elem)) {
                show = false;
            }
        });

        if (axisNumber && show) {
            if (this.currentSection !== axisNumber) {
                this.currentSection = axisNumber;

                document.querySelector("body").classList.add("show-crono-caller");
                let currentCaller = document.querySelector(".crono_side.show");
                if (currentCaller) {
                    currentCaller.classList.remove("show");
                }

                let callerId = "#crono-axis-caller-" + axisNumber;
                let caller = document.querySelector(callerId);

                if (caller) {
                    caller.classList.add("show");
                } else {
                    document.querySelector("body").classList.remove("show-crono-caller");
                }
            }
        } else {
            this.currentSection = null;
            document.querySelector("body").classList.remove("show-crono-caller");
            let currentCaller = document.querySelector(".crono_side.show");
            if (currentCaller) {
                currentCaller.classList.remove("show");
            }
        }
    }

    update() {
        let viewableSections = [];

        let self = this;
        this.axis.forEach((elem) => {
            if (self.isInViewport(elem)) {
                viewableSections.push(elem.dataset.axisnumber);
            }
        });

        if (viewableSections.length > 0) {
            self.updateCaller(viewableSections[0]);
        } else {
            self.updateCaller(null);
        }
    }
}
