import { docReady, windowReady } from "./utils/utils.js";
import MainNav from "./main_nav.js";
import VerticalNav from "./vertical_nav.js";
import PopBox from "./pop_box.js";
import Crono from "./crono.js";

docReady(() => {
    let mainNav = new MainNav();
    mainNav.run();

    let verticalNav = new VerticalNav();
    verticalNav.run();

    let popBox = new PopBox();
    popBox.run();

    let crono = new Crono();
    crono.run();
});

windowReady(() => {
    // ISOTOPE
    let isoContainerElem = document.querySelector(".iso-grid");

    if (isoContainerElem) {
        var font0 = new FontFaceObserver("Archivo");
        var font1 = new FontFaceObserver("Chivo");
        var font2 = new FontFaceObserver("Oswald");

        Promise.all([font0, font1, font2]).then(() => {
            let iso = new Isotope(isoContainerElem, {
                // options
                itemSelector: ".iso-grid-item",
                // layoutMode: "masonry",
                layoutMode: "fitRows",
            });

            let filterButtons = document.querySelectorAll(".iso-filter");

            let filterOp = (elem) => {
                if (elem.classList.contains("selected")) {
                    filterButtons.forEach((filter) => {
                        filter.classList.remove("selected");
                    });

                    iso.arrange({
                        filter: "*",
                    });
                } else {
                    filterButtons.forEach((filter) => {
                        filter.classList.remove("selected");
                    });

                    elem.classList.add("selected");
                    let filterClass = "." + elem.dataset["isoFilter"];

                    iso.arrange({
                        filter: filterClass,
                    });
                }
            };

            filterButtons.forEach((filter) => {
                filter.addEventListener("click", (event) => {
                    event.preventDefault();
                    filterOp(filter);
                });
            });
        });
    }

    // ROUND MAPS
    let roundMapContainer = document.querySelector("#round_maps_container");

    if (roundMapContainer) {
        let round1Button = document.querySelector("#round_button1");
        let round2Button = document.querySelector("#round_button2");
        let round3Button = document.querySelector("#round_button3");

        round1Button.addEventListener("click", event => {
            roundMapContainer.classList.remove("round2");
            roundMapContainer.classList.remove("round3");
            roundMapContainer.classList.add("round1");
        });

        round2Button.addEventListener("click", event => {
            roundMapContainer.classList.remove("round1");
            roundMapContainer.classList.remove("round3");
            roundMapContainer.classList.add("round2");
        });

        round3Button.addEventListener("click", event => {
            roundMapContainer.classList.remove("round1");
            roundMapContainer.classList.remove("round2");
            roundMapContainer.classList.add("round3");
        });
    }
});
